import { render, staticRenderFns } from "./JudoItem.vue?vue&type=template&id=24c6a341&scoped=true"
import script from "./JudoItem.vue?vue&type=script&lang=js"
export * from "./JudoItem.vue?vue&type=script&lang=js"
import style0 from "./JudoItem.vue?vue&type=style&index=0&id=24c6a341&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c6a341",
  null
  
)

export default component.exports