<template>
  <section class="main-body">
    <h3 class="subheading">Judo Campaign</h3>

    <section class="section-container">
      <v-layout row class="published-wrap">
        <v-flex>
          <div class="published-row">
            <label class="label published-row__label" for="published">Published</label>
            <v-checkbox
              id="published"
              v-model="roverItem.published"
              @change="updateData()"
            ></v-checkbox>
          </div>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="expiry">Expiry date</label>

          <datetime
            v-model="roverItem.expiryUTCDateTime"
            type="datetime"></datetime>

          <p class="utc-note">This will expire at {{ roverItem.expiryUTCDateTime }}</p>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Please enter the expiry date and time as your local time if you want the post to automatically remove</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="order">Position*</label>
          <v-text-field
            id="order"
            v-model="roverItem.forcedOrder"
            type="number"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required</i></p>
          <p><i>Change this value to update the location of the campaign in the feed. This number will automatically adjust to it's correct position once saved.</i></p>
          <p><i>For example changing this to <b>1</b> will make it the first item.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading">Campaign Details</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="rover-title">Title*</label>
          <v-text-field
            id="rover-title"
            v-model="roverItem.title"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required - Name of campaign. This will be displayed in the admin and for users.</i></p>
        </v-flex>
      </v-layout>

      <!-- <v-layout v-if="this.roverItem.roverID" row>
        <v-flex mr-5 class="half">
          <label class="label" for="rover-id">ID*</label>
          <v-text-field
            id="rover-id"
            v-model="roverItem.roverID"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required - ID from Judo to identify the correct campaign.</i></p>
        </v-flex>
      </v-layout> -->

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="rover-url">URL*</label>
          <v-text-field
            id="rover-url"
            v-model="roverItem.url"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required - URL to link to Judo campaign.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="rover-access-level">Access level</label>
          <v-select
            id="rover-access-level"
            v-model="roverItem.accessLevel"
            item-text='name'
            item-value='value'
            :items="[
              { name: 'Requires login', value: 'login-required' },
              { name: 'Requires premium', value: 'premium-required' },
              { name: 'Open to all', value: '' }
            ]"
            solo
            flat
            required
            @change="status = 'needsSaving'"/>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required - URL to link to Judo campaign.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="rewardPointsEnabled">
        <v-flex mr-5 class="half">
          <label class="label" for="reward-point-value">Reward Point Value</label>
          <v-text-field
            type="number"
            id="reward-point-value"
            v-model="roverItem.rewardPointsValue"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Reward points to be awarded</i></p>
        </v-flex>
      </v-layout>

      <ImageUploader :title="'App Image*'" :widthOptionEnabled="false" :mediaLibraryID="roverItem.mediaLibraryID" @update="updateImage($event)" :desc="'<p>*Required. Recommended size: 800px width by 600px height</p>'"/>
    </section>

    <v-btn v-if="!newItem" color="error" @click="deleteRoverItem()">Delete</v-btn>

    <StickyFooter v-if="newItem" :errorMessage="errorMessage" :status="status" @buttonClicked="newRoverItem()" />
    <StickyFooter v-else :errorMessage="errorMessage" :status="status" @buttonClicked="saveRoverItem()" />
  </section>
</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/Library/ImageUploader"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "JudoItem",

  components: {
    ImageUploader,
    StickyFooter
  },

  data: () => ({
    api: `${process.env.VUE_APP_ROVER}/rover`,
    status: "saved",
    errorMessage: "",
    modules: null,
    rewardPointsEnabled: false,
    newItem: false,
    roverItem: {
      published: false,
      expiryUTCDateTime: "",
      title: "",
      roverID: "",
      mediaLibraryID: "",
      url: "",
      accessLevel: "",
      rewardPointsValue: 0,
      forcedOrder: 1,
    },
  }),

  created() {
    this.modules = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
  },

  mounted() {
    this.rewardPointsEnabled = this.modules.default.RewardPoints

    if (this.$route.params.id === "create") {
      this.newItem = true
    } else {
      this.fetchRoverItem();
    }
  },

  methods: {
    updateData() {
      this.status = "needsSaving";
    },

    fetchRoverItem() {
      this.status = "saving";
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}/getsingle?roverID=${this.$route.params.id}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("fetchRoverItem: ", response);
          this.roverItem = response.data.body;

          this.roverItem.forcedOrder = this.roverItem.forcedOrder / 10;

          this.status = "saved";

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving rover item", e);
          this.status = "needsSaving";
          this.$store.commit("completeLoading");
        });
      })
    },

    saveRoverItem() {
      this.$store.commit("startLoading");

      this.roverItem.rewardPointsValue = parseInt(this.roverItem.rewardPointsValue)

      // if (!this.roverItem.roverID) {
      //   alert('You must have a Rover ID')
      // } else {
        this.roverItem.forcedOrder = this.roverItem.forcedOrder * 10 - 1;
        this.roverItem.published = this.roverItem.published ? 1 : 0;

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${this.api}/update`,
            headers: { "Authorization": jwt },
            data: this.roverItem
          })
          .then(response => {
            console.log("Rover item updated", response)
            this.fetchRoverItem()
          })
          .catch(e => {
            console.error("Problem updating rover item", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem updating rover item";
            this.$store.commit("completeLoading");
          });
        })
      // }
    },

    newRoverItem() {
      this.$store.commit("startLoading");

      this.roverItem.rewardPointsValue = parseInt(this.roverItem.rewardPointsValue)

      // if (!this.roverItem.roverID) {
      //   alert('You must have a Rover ID')
      // } else {
        this.roverItem.forcedOrder = this.roverItem.forcedOrder * 10 - 1;
        this.roverItem.published = this.roverItem.published ? 1 : 0;

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${this.api}/add`,
            headers: { "Authorization": jwt },
            data: this.roverItem
          })
          .then(response => {
            console.log("Rover item added", response)
            this.newItem = false

            // this.$router.push({ path: `/judo/${this.roverItem.roverID}` })
            this.$router.push({ path: "/judo" })
            this.fetchRoverItem()
          })
          .catch(e => {
            console.error("Problem adding rover item", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem adding rover item";
            this.$store.commit("completeLoading");
          });
        })
      // }
    },

    deleteRoverItem() {
      if (confirm("Are you sure you want to delete this?")) {
        console.warn("Deleting")
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "DELETE",
            url: `${this.api}/delete?roverID=${this.$route.params.id}`,
            headers: { "Authorization": jwt },
          })
          .then(response => {
            console.log("Rover item deleted")
            this.$router.push({ path: "/judo" })
          })
          .catch(e => {
            console.error("Problem deleting rover item", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem deleting rover item";
          });
        })
      }
    },

    updateImage(payload) {
      console.log("updateImage", payload)
      this.status = "needsSaving";
      this.roverItem.mediaLibraryID = payload.mediaLibraryID
      this.roverItem.imageKey = payload.imageKey
    }
  }
}
</script>

<style scoped lang="scss">
  .half {
    width: 50%;
  }

  .section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DFE3E9;
  }
</style>
